import { render, staticRenderFns } from "./assistentForm.vue?vue&type=template&id=779e90ab&scoped=true"
import script from "./assistentForm.vue?vue&type=script&lang=js"
export * from "./assistentForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "779e90ab",
  null
  
)

export default component.exports