<template>
  <div v-if="data" :key="updateKey">
    <div class="flix-form-group" v-for="part in data" :key="part.id + Math.random(0, 1)">
      <div v-if="part.type === 'text'">
        <div class="flix-form-group">
          <a href="#" @click.prevent="setOpen(part.id)" class="flix-html-a"><flixIcon id="wrench" /> {{ $t(getLabel(part.label), {name: part.label}) }}</a>
        </div>
        <div v-if="open[part.id] === true">
          <input :value="save[part.label]" :data-title="part.label" v-on:keyup="setText" :id="part.id" type="text" :subtype="part.subtype" class="flix-form-control" :placeholder="part.label + ' (' + $t('message.optional') + ')'" />
        </div>
      </div>
      <div v-else-if="part.type === 'textarea'">
        <div class="flix-form-group">
          <a href="#" @click.prevent="setOpen(part.id)" class="flix-html-a"><flixIcon id="wrench" /> {{ $t(getLabel(part.label), {name: part.label}) }}</a>
        </div>
        <div v-if="open[part.id] === true">
          <textarea :value="save[part.label]" :key="open[part.id]" v-if="open[part.id]" :data-title="part.label" v-on:keyup="setText" :id="part.id" type="text" :subtype="part.subtype" class="flix-form-control" :placeholder="part.label + ' (' + $t('message.optional') + ')'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    user: String,
    id: [String, Number],
    callback: Function,
    entry: Object
  },
  data () {
    return {
      data: false,
      save: {},
      open: {},
      updateKey: new Date().getTime()
    }
  },
  methods: {
    getLabel (label) {
      if (typeof this.save[label] === 'undefined') {
        return 'message.add'
      } else {
        return 'message.edit'
      }
    },
    setOpen (id) {
      this.open[id] = !this.open[id]
      this.updateKey = new Date().getTime()
    },
    setText (event) {
      this.save[event.target.getAttribute('data-title')] = event.target.value
      this.callback(this.save, this.data)
    },
    getData (callback) {
      this.$flix_post({
        url: 'assistent/get_data',
        data: {
          user: this.user,
          ID: this.id
        },
        callback: function (ret) {
          this.data = ret.data[1].form
          this.data.forEach(function (d) {
            this.open[d.id] = false
          }.bind(this))
          callback()
        }.bind(this)
      })
    },
    setEntry () {
      if (typeof this.entry !== 'object' || typeof this.entry.data !== 'object') {
        return false
      }
      Object.keys(this.entry.data).forEach(function (k) {
        this.save[k] = this.entry.data[k]
      }.bind(this))
      this.updateKey = new Date().getTime()
    }
  },
  mounted () {
    this.getData(function () { this.setEntry() }.bind(this))
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
